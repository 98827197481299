<template>
    <div>
        <ol>
            <li>
                <div class="fw-bold">TEILNAHME:</div>
                <div>Die für das teilnehmende (Einzel-)Unternehmen (nachfolgend “Teilnehmer” genannt) bindenden
                    Teilnahmevoraussetzungen an BS PLUS werden wie hier dargelegt definiert. Der Bridgestone Außendienst
                    stellt das entsprechende Formular zur Verfügung. Die Durchführung übernimmt die TAKTZEIT GmbH –
                    Marketing Kommunikation (nachfolgend “TAKTZEIT” genannt). Ein Anspruch des Teilnehmers auf Zulassung
                    zur Teilnahme besteht gegenüber Bridgestone bzw. TAKTZEIT nicht. Insbesondere ist die Teilnahme von
                    Filialnetzwerken ausgeschlossen. Soweit von Bridgestone nicht ausdrücklich anders bestimmt, ist eine
                    Übertragung oder Abtretung des Teilnehmerkontos ausgeschlossen. Das Teilnehmerkonto ist nicht
                    pfändbar. Änderungen der geschäftlichen oder ggf. persönlichen Daten des Teilnehmers sind
                    unverzüglich online zu hinterlegen. Kommt der Teilnehmer dieser Verpflichtung nicht nach, hat er
                    sämtliche hieraus entstehenden Mehrkosten, Verzögerungen, die Gefahr der Beschädigung oder des
                    Verlustes der Prämie sowie sonstige Schäden zu vertreten. Teilnahmeberichtigte sind alle
                    inhabergeführten Reifenhändler in Deutschland mit Schwerpunkt auf Hofgeschäft, die ihre Reifen
                    direkt bei der Bridgestone Europe NV/SA oder einer ihrer Niederlassungen beziehen und eine
                    Bridgestone Kundennummer besitzen. Der Teilnehmer kann seine Teilnahme jederzeit durch schriftliche
                    Erklärung an TAKTZEIT oder Bridgestone beenden.
                </div>
            </li>
            <li>
                <div class="fw-bold">PUNKTEGUTSCHRIFTEN FÜR TEILNEHMENDE DES PUNKTEPROGRAMMS</div>
                <div>
                    Die Punkte im Rahmen des Punkteprogramms werden dem Teilnehmer ausschließlich auf Veranlassung von
                    Bridgestone und nach den in den Teilnahmebedingungen festgelegten Voraussetzungen, gutgeschrieben.
                    Mit diesen Punkten kann sich der Teilnehmer sodann im Prämienshop eine Prämie auswählen.
                    <br />
                    Punkterelevanter Zeitraum 01.01. – 31.12. des jeweiligen Jahres
                    Abrechnungsperioden zur Errechnung der Punkte: Januar – Dezember desselben Jahres
                    <br />
                    Für alle Produkte der Marken Bridgestone und Firestone wird grundsätzlich ein Punkt pro Reifen
                    gutgeschrieben. Bridgestone Europe NV/SA behält sich vor, aufgrund individueller vertrieblicher
                    Kriterien, die finale Bepunktung eines Bridgestone oder Firestone Produkts höher ausfallen zu
                    lassen. Die Punkte werden dem Account des Teilnehmers umgehend nach der ihrer Ausschüttung
                    zugrundeliegenden Verkaufsanalyse ein Mal pro Monat gutgeschrieben.
                    <br />
                    Die Bepunktung gilt nur für Neuware mit einer DOT unter zwei Jahren.
                    <br />
                    Ein Übertrag sämtlicher in einem Jahr gesammelter Punkte in das Folgejahr ist einmalig möglich.
                    <b>Sämtliche nicht abgerufene Punkte des laufenden Jahres verfallen zum 01. März des übernächsten
                        Jahres.</b>
                </div>
            </li>
            <li>
                <div class="fw-bold">DATENGRUNDLAGE ZUR BERECHNUNG DER PUNKTE</div>
                <div>
                    <div>Mit der Anmeldung zur Teilnahme bei BS PLUS stimmt der Teilnehmer zu, dass diejenigen Händler
                        auf Großhandelsstufe (nachfolgend “Wholesaler” genannt), die der Teilnehmer bei seiner
                        Registrierung (oder später) als Lieferanten angibt und bzgl. derer er seine Kundennummer im
                        Programm hinterlegt, an Bridgestone folgende Daten aus der Geschäftsbeziehung mit dem jeweiligen
                        Wholesaler weiterleiten:</div>
                    <div><b>Best4Tires GmbH</b></div>
                    <div>Diese Daten werden Grundlage der Berechnung der dem Teilnehmer zustehenden Punkte.
                        Der Teilnehmende stimmt ferner zu, dass zum Zwecke der weiteren Aktivierung und Unterstützung
                        des Teilnehmers folgende Profilinformationen bezogen auf sein Geschäft mit dem jeweiligen
                        Wholesaler diesem auf einem Dashboard im System dargestellt werden:
                    </div>
                    <ul>
                        <li>Kundennummer beim jeweiligen Wholesaler</li>
                        <li>Firmierung</li>
                        <li>Registrierungsdatum</li>
                        <li>Letzter Login</li>
                        <li>Summe der gesammelten Punkte</li>
                    </ul>
                </div>
            </li>
            <li>
                <div class="fw-bold">PRÄMIEN FÜR TEILNEHMENDE DES PUNKTEPROGRAMMS</div>
                <div>
                    <div>Für den Prämienabruf hat das teilnehmende Unternehmen eine/n Mitarbeiter/in bzw. eine/n
                        Erfüllungsgehilfen/in zu benennen oder bereits benannt, welche/r im Namen des Teilnehmers
                        die Prämien abrufen darf. Diese Person hat entsprechend Zugriff auf das Punktekonto des
                        teilnehmenden Unternehmens.</div>
                    <div>
                        Der Teilnehmer kann die gutgeschriebenen Punkte gegen die angebotenen Sachprämien
                        und/oder angebotenen Dienstleistungen einlösen. Eine Auszahlung des Gegenwertes der
                        gesammelten Punkte ist, soweit dies nicht ausdrücklich im Prämiensystem angeboten wird,
                        ausgeschlossen. Eine etwaige Verzinsung findet nicht statt. Die Einlösung der gesammelten
                        Punkte ist dann möglich, wenn das Punktekonto mindestens den für die jeweilige Prämie
                        erforderlichen Punktestand ausweist. Ein Anspruch des Teilnehmers auf eine bestimmte Prämie
                        besteht nicht. Eine Einlösung ist nur so lange möglich, wie Vorrat reicht. TAKTZEIT behält
                        sich vor, offensichtliche Falschauszeichnungen der Prämien zu korrigieren. Eingelöste Punkte
                        können nicht in Punkte oder in Prämien eingetauscht oder zurückgetauscht werden; bei einer
                        defekten Prämie wird TAKTZEIT einen Ersatzartikel liefern. Sofern letzteres nicht möglich ist,
                        werden dem Teilnehmer ausnahmsweise die Punkte zurückerstattet. Diese werden dann so
                        behandelt, als wären sie erst im Moment der erneuten Gutschrift erstmals gutgeschrieben
                        worden.
                    </div>
                    <div>
                        Nach erfolgter Bestellung erhält der Teilnehmer sofort eine Eingangsbestätigung. TAKTZEIT
                        überprüft dann unverzüglich alle relevanten Daten und die Verfügbarkeit der Prämie. Alle
                        Leistungs-, Maß-, Gewichts- und Größenangaben sind, soweit nicht anders angegeben, Circa-
                        Werte und dienen lediglich der Beschreibung, sie stellen keine zugesicherten Eigenschaften
                        dar. Die Lieferzeiten werden dem Teilnehmer nach der Bestellung gesondert mitgeteilt. Sollte
                        die bestellte Prämie nicht lieferfähig oder nicht verfügbar sein, so behalten sich Bridgestone
                        bzw. TAKTZEIT vor, dem Teilnehmer eine vergleichbare ähnliche Prämie zuzusenden bzw. die
                        Bestellung unter Rückerstattung der Punkte zu stornieren.
                    </div>
                    <div>Die Prämie „Investitionsunterstützung“ ist nur für bereits getätigte Investitionen gültig, die
                        dem Reifen- und Räder-Geschäft dienen.</div>
                    <div> Die Unterstützung kann nur mit Ihrer Rechnungsstellung, einer Kopie der Originalrechnung
                        der Anschaffungs- und Herstellungskosten (nicht älter als 3 Jahre), sowie Belegfotos beantragt
                        werden.</div>
                    <div>Die Investitionsunterstützung kann nur wie folgt beantragt werden:</div>
                    <div> Bitte rufen Sie die Prämie online im BS PLUS ab. Nach dem Prämienabruf senden Sie bitte Ihre
                        Rechnung über eine „Investitionsunterstützung für …“ in Höhe der zu einlösenden Punkte an
                        folgende Rechnungsadresse:</div>
                    <div>
                        Bridgestone Europe NV/SA <br />
                        Niederlassung Deutschland <br />
                        PKW Marketing - BS PLUS <br />
                        Franklinstr. 61 – 63 <br />
                        60486 Frankfurt am Main <br />
                        Deutschland <br />
                        Auf der Rechnung muss die Bridgestone Kundennummer sowie die Kostenstelle vermerkt sein. <br />
                        Bitte kontaktieren Sie hierzu: <br />
                        TAKTZEIT GmbH – Marketing Kommunikation <br />
                        Burgmüllerstraße 28 <br />
                        40235 Düsseldorf <br />
                        Deutschland <br />
                        T. +49 (0) 211 . 36 11 789 – 10 <br />
                        F. +49 (0) 211 . 36 11 78 99 <br />
                        www.taktzeit.com <br />
                    </div>
                    <div>
                        Als Anlage fügen Sie bitte eine Kopie der Originalrechnung für Ihre Anschaffung sowie
                        Belegfotos bei Sachgegenständen Ihrer Rechnung bei.
                    </div>
                    <div> Bitte beachten Sie:</div>
                    <div>Die Bearbeitung Ihrer Investitionsunterstützung erfolgt nur bei vollständigem
                        Rechnungseingang inklusive Anlagen. Bei fehlenden oder nicht nachvollziehbaren Nachweisen
                        kann Ihre Anfrage nicht bearbeitet werden und Sie laufen Gefahr, dass Ihre Punkte verfallen.
                        In Ihrem Interesse achten Sie deshalb dringend auf die Richtigkeit und Vollständigkeit der
                        eingereichten Unterlagen.</div>
                    <div>
                        Die Bearbeitung der Investitionsunterstützung kann 8 Wochen in Anspruch nehmen.</div>
                </div>
            </li>
            <li>
                <div class="fw-bold">PUNKTESTAND FÜR TEILNEHMENDE DES PUNKTEPROGRAMMS / PASSWORT</div>
                <div>
                    Die Punkte werden bei TAKTZEIT unter der Teilnehmernummer des Teilnehmers registriert.
                    Einwendungen gegen die Richtigkeit oder Vollständigkeit der Punktestandsmitteilung müssen
                    spätestens innerhalb eines Monats nach Kenntniserlangung schriftlich (E-Mail ist ausreichend)
                    und mit entsprechendem Nachweis der Unrichtigkeit und/oder Unvollständigkeit geltend
                    gemacht werden. Das Unterlassen rechtzeitiger Geltendmachung gilt als Genehmigung des
                    Punktestandes. bei eigenem grobe Verschulden unter Berücksichtigung eines etwaigen
                    Mitverschuldens des Teilnehmers.
                </div>
            </li>
            <li>
                <div class="fw-bold">VERSTEUERUNG FÜR TEILNEHMENDE DES PUNKTEPROGRAMMS</div>
                <div> Durch die private Nutzung der Prämien entsteht dem Empfänger ein steuerpflichtiger
                    geldwerter Vorteil. Bridgestone übernimmt für Sie die Einkommensteuer und wendet hierfür
                    die Pauschalierung der ESt nach § 37b EStG an. Eine Versteuerung der Prämien durch den
                    Empfänger ist nicht notwendig.</div>
            </li>
            <li>
                <div class="fw-bold">DEFEKTE/MÄNGEL FÜR TEILNEHMENDE DES PUNKTEPROGRAMMS</div>
                <div>Mängel an den gelieferten Artikeln müssen unverzüglich schriftlich angezeigt werden. Im
                    Übrigen gelten die gesetzlichen Vorschriften.</div>
            </li>
            <li>
                <div class="fw-bold">KÜNDIGUNG</div>
                <div> Die Teilnahme an BS PLUS kann vom Teilnehmer jederzeit gegenüber Bridgestone gekündigt
                    werden, die Einlösung der gesammelten Punkte bleibt hiervon unberührt. Bridgestone behält
                    sich seinerseits vor, einen Teilnehmer aus wichtigen Gründen von der Teilnahme
                    auszuschließen. Das Konto des Teilnehmers wird in solchen Fällen bei Erreichung eines
                    Punktestandes von null Punkten – sei es durch Einlösung aller der noch darauf befindlichen
                    Punkte oder deren Verfall durch Zeitablauf – automatisch geschlossen.</div>
            </li>
            <li>
                <div class="fw-bold">HAFTUNG</div>
                <div>Für andere als durch Verletzung von Leben, Körper und Gesundheit entstehende Schäden
                    haften Bridgestone und TAKTZEIT lediglich, soweit diese auf vorsätzlichem oder grob
                    fahrlässigem Handeln oder auf schuldhafter Verletzung einer wesentlichen Vertragspflicht
                    durch Bridgestone bzw. TAKTZEIT oder deren Erfüllungsgehilfen (z.B. Zustelldienst) beruhen.
                    Eine darüber hinausgehende Haftung auf Schadensersatz ist ausgeschlossen.
                    Produkthaftungsgesetzliche Bestimmungen bleiben unberührt. Die Datenkommunikation über
                    das Internet kann nach dem derzeitigen Stand der Technik nicht fehlerfrei und/oder jederzeit
                    gewährleistet werden. Weder Bridgestone noch TAKTZEIT haften daher für die ständige und
                    ununterbrochene Verfügbarkeit der Online-Präsentation oder für technische und elektronische
                    Fehler, auf die Bridgestone bzw. TAKTZEIT keinen Einfluss haben, insbesondere nicht für
                    verzögerte Bearbeitung oder Annahme von Angeboten.</div>
            </li>
            <li>
                <div class="fw-bold">ÄNDERUNG DER TEILNAHME- UND BESTELLBEDINGUNGEN FÜR
                    TEILNEHMENDE DES PUNKTEPROGRAMMS</div>
                <div> TAKTZEIT und Bridgestone behalten sich jeweils vor, das Prämiensystem unter Einhaltung
                    einer Frist von 3 Monaten, bei Vorliegen eines wichtigen Grundes auch ohne Einhaltung einer
                    solchen Frist, unter angemessener Wahrung der Belange und Interessen des Teilnehmers
                    einzustellen. TAKTZEIT und/oder Bridgestone behalten sich ferner vor, die Bedingungen zu
                    ändern oder zu ergänzen, soweit dies im Interesse einer einfacheren und sicheren Abwicklung
                    und insbesondere zur Verhinderung von Missbräuchen erforderlich ist. Änderungen werden
                    dem Teilnehmer vorab mitgeteilt. Eine Änderung und/oder Ergänzung gilt als genehmigt, wenn
                    nicht innerhalb eines Monats nach Kenntniserlangung gekündigt wird oder aber, wenn der
                    Teilnehmer nach Ablauf dieser Frist erneut am Prämienprogramm teilnimmt.</div>
            </li>
            <li>
                <div class="fw-bold">HINWEISE ZUM DATENSCHUTZ</div>
                <div>
                    Zur Teilnahme an BS PLUS müssen verschiedene personenbezogene Daten erhoben,
                    gespeichert und genutzt werden. Diese Daten sind erforderlich, um für den Teilnehmer ein
                    persönliches Punktekonto zu eröffnen und um ihm die das Prämienprogramm betreffenden
                    Informationen zusenden zu können. Die zur Teilnahme zwingend erforderlichen Daten
                    umfassen Firmierung, Name, Vorname, Position im Unternehmen, vollständige Postanschrift,
                    Telefonnummer und E-Mail-Adresse. Zum Zwecke der Abwicklung und Durchführung von BS
                    PLUS werden die Daten des Teilnehmers durch TAKTZEIT, Bridgestone oder durch ein von
                    TAKTZEIT beauftragtes Dienstleistungsunternehmen gespeichert und genutzt. Bridgestone und
                    TAKTZEIT verpflichten sich weiterhin zur Einhaltung der gesetzlichen
                    Datenschutzbestimmungen. Eine Weitergabe an Dritte erfolgt nur dann, wenn diese Dritte als
                    Erfüllungsgehilfen dienen, bspw. für die Abwicklung einer Prämienbestellung. Mit Teilnahme
                    an dem Prämienprogramm erklärt sich der Teilnehmer durch Bestätigung dieser
                    einverstanden; diese kann jederzeit schriftlich widerrufen werden. Mit der
                    Einverständniserklärung erhält der Teilnehmer den dazugehörigen Newsletter. Es besteht zu
                    jeder Zeit die Möglichkeit den Newsletter über den Menüpunkt „Kontakt > Newsletter
                    abbestellen“ abzubestellen.
                </div>
            </li>
            <li>
                <div class="fw-bold">SCHLUSSBESTIMMUNGEN</div>
                <div>
                    Änderungen oder Ergänzungen dieser Geschäftsbedingungen bedürfen der Schriftform. Dies
                    gilt auch für die Aufhebung dieses Schriftformerfordernisses. Es gilt das Recht der
                    Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts. Unberührt bleibende
                    zwingende Bestimmungen des Staates, in dem der Teilnehmer seinen gewöhnlichen Aufenthalt
                    hat. Sofern der Teilnehmer keinen allgemeinen Gerichtsstand in der Bundesrepublik
                    Deutschland hat oder nach Vertragsschluss den Hauptsitz des Unternehmens ins Ausland
                    verlegt oder der Hauptsitz zum Zeitpunkt der Klageerhebung nicht bekannt ist, ist
                    Gerichtsstand für alle Streitigkeiten der Sitz von TAKTZEIT in Düsseldorf. Sollten einzelne
                    Bestimmungen dieser Geschäftsbedingungen unwirksam sein oder den gesetzlichen
                    Regelungen widersprechen, so wird hierdurch der Vertrag im Übrigen nicht berührt. Die
                    unwirksame Bestimmung wird von den Vertragsparteien einvernehmlich durch eine solche
                    Bestimmung ersetzt, welche dem wirtschaftlichen Sinn und Zweck der unwirksamen
                    Bestimmung in rechtswirksamer Weise am nächsten kommt. Vorstehende Regelung gilt
                    entsprechend bei Regelungslücken. Mündliche Absprachen sind gegenstandslos. Es gelten nur
                    die in den AGBs genannten Teilnahmebedingungen.
                </div>
            </li>
            <li>
                <div class="fw-bold">ANBIETERKENNZEICHNUNG</div>
                <div>
                    TAKTZEIT GmbH – Marketing Kommunikation Burgmüllerstraße 28, D-40235 Düsseldorf T.
                    +49 (0) 211 . 36 11 789 - 10 F. +49 (0) 211 . 36 11 78 99 I. www.taktzeit.com
                    Eingetragen beim Amtsgericht Düsseldorf: HRB 55457 Sitz der Gesellschaft: Düsseldorf
                    Geschäftsführer: Sven-Carsten Hennings, Till Belkoura USt-Id Nr.: DE251841643,
                    Steuernr. 105/5845/3744
                </div>
            </li>
            <li>
                <div class="fw-bold">RÜCKGABEBELEHRUNG FÜR TEILNEHMENDE DES PUNKTEPROGRAMMS</div>
                <div>
                    Der Teilnehmer erhält ein 14-tägiges Rückgaberecht. Im Falle eines Rückgabewunsches hat er
                    sich schriftlich zu wenden an: <br />
                    Bridgestone Europe NV/SA <br />
                    Niederlassung Deutschland <br />
                    - BS PLUS - <br />
                    Franklinstr. 61 - 63 <br />
                    60486 Frankfurt am Main <br />
                    Bridgestone wird dem Teilnehmer die weiteren Rückgabemodalitäten zukommen lassen. Die
                    Frist beginnt mit Erhalt der Ware. Bei einer Rücksendung aus einer Warenlieferung hat der
                    Besteller die Kosten der Rücksendung zu tragen, wenn die gelieferte Ware der bestellten Ware
                    entspricht. Die vom Teilnehmer für die Prämie eingelösten Punkte werden wieder dem
                    Punktekonto gutgeschrieben. Das Rückgaberecht ist ausgeschlossen bei Artikeln, die auf Grund
                    von Spezifikationen angefertigt wurden, die eindeutig auf persönliche Bedürfnisse
                    zugeschnitten sind oder bei denen die Rückgabe gesetzlich (z.B. Hygieneartikel) oder aus
                    anderen Gründen (z.B. zum Verzehr bestimmte Artikel) ausgeschlossen ist. <br />
                    FRANKFURT, STAND AUGUST 2024
                </div>
            </li>
        </ol>
    </div>
</template>