<template>
  <MDBContainer>
    <Menu />
    <MDBRow>
      <MDBCol col="lg-3" :class="`column-order justify-content-start ${props.switchOrderMobile ? 'order-lg-1 order-2 mt-lg-0 mt-5' : null
        }`">
        <LeftColumn />
      </MDBCol>
      <MDBCol col="lg-9" :class="`${props.switchOrderMobile ? 'order-lg-2 order-1' : null}`">
        <props.content />
      </MDBCol>
    </MDBRow>
    <MDBRow class="mt-5">
      <MDBCol col="lg-3">
        <MDBRow v-if="route.name == 'dashboard'">
          <MDBCol class="my-3">
            <h5>&nbsp;</h5>
          </MDBCol>
        </MDBRow>
        <template v-if="!isWholesaler && !isWholesalerStaff">
          <PointBox />
          <KickbackBox />
        </template>
      </MDBCol>
      <MDBCol col="lg-9">
        <props.data />
        <div class="mt-3" v-if="route.name == 'dashboard' && isNetherland">
          <a href="https://bridgestone.touchtickets.nl/nl/" target="_blank">
            <img src="@/assets/img/372_Banniere-CarWash-1192x204.jpg" /></a>
        </div>
      </MDBCol>
    </MDBRow>
    <MDBRow class="mt-1" v-if="
      route.name == 'dashboard' &&

      showDefaultBanners &&
      defaultBanners.length > 0
    ">
      <MDBCol col="12" :md="12 / defaultBanners.length" v-for="(banner, index) in defaultBanners" :key="index">
        <img :src="banner.path" :alt="banner.path" />
      </MDBCol>
    </MDBRow>
    <Footer />
  </MDBContainer>

  <MDBModal v-model="showDisclaimer" tabindex="-1" centered size="xl" @hide="closeDisclaimer" class="bg-modal"
    staticBackdrop>
    <Marketing @close="(e) => closeDisclaimer()" />
  </MDBModal>
</template>

<script setup>
import { MDBCol, MDBRow, MDBContainer, MDBModal } from "mdb-vue-ui-kit";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import Menu from "@/components/Layout/Menu.vue";
import Footer from "@/components/Layout/Footer.vue";
import LeftColumn from "@/components/Layout/LeftColumn.vue";
import Marketing from "@/components/Intro/Marketing.vue";
import KickbackBox from "@/components/Dashboard/KickbackBox.vue";
import PointBox from "@/components/Dashboard/PointBox.vue";
import { defineProps, ref, watch, computed, onMounted } from "vue";
import TokenService from "@/services/storage.service";

const LANG = localStorage.getItem("lang");
const isNetherland = computed(() => LANG === "nl");
const isGermany = computed(() => LANG === "de");
const store = useStore();
const showDefaultBanners = ref(false);

const route = useRoute();
const props = defineProps({
  content: Object,
  switchOrderMobile: Boolean,
  data: Object,
});

const user = computed(() => store.getters.getMe);
const defaultBanners = computed(() => store.getters.getDefaultBanners);
const isWholesalerStaff = ref(false);
const isWholesaler = ref(false);
const showDisclaimer = ref(false)
watch(user, async (newVal) => {
  if (newVal && newVal.roles.find((item) => item.name == "wholesaler")) {
    isWholesaler.value = true;
  }
  if (newVal && newVal.roles.find((item) => item.name == "wholesalerStaff")) {
    isWholesalerStaff.value = true;
  }
});

const closeDisclaimer = () => {
  showDisclaimer.value = false
};

onMounted(async () => {
  if (
    user.value &&
    user.value.roles &&
    user.value.roles.find((item) => item.name == "dealer")
  ) {
    await store.dispatch("me");
    await store.dispatch("loadUseProfilePercentage");
  }
  showDisclaimer.value = user.value && !user.value.commitment_letter_accept && (isNetherland.value || isGermany.value)

  showDefaultBanners.value = TokenService.showElement(
    "mainPage.defaultBanners"
  );

  if (showDefaultBanners.value) {
    await store.dispatch("fetchDefaultBanners");
  }
});
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";

.bg-modal {
  background-color: rgba(0, 0, 0, 0.8) !important;
}
</style>
