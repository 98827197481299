<template>
    <MDBRow>
        <MDBCol cols="12" lg="6" xl="4" class="mb-4" v-for="(training, i) in trainings" :key="i"
            :class="`${training.show ? '' : 'd-none'}`">
            <Card :data="training" :simpleLink="training.simpleLink" :target="training.target"
                :linkText="training.linkText" />
        </MDBCol>
    </MDBRow>
</template>

<script setup>
import Card from "@/components/General/Card/index.vue";
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
import { ref } from "vue";
import i18n from "@/plugins/i18n";

const trainings = ref([
    {
        name: i18n.global.t("productInfo.index.categories.bDrive.title"),
        link: 'https://www.b-drive.com',
        description: i18n.global.t("productInfo.index.categories.bDrive.description"),
        image: require("@/assets/img/ProduktabbildungenAndLogo.png"),
        show: true,
        simpleLink: true,
        target: "_blank",
        linkText: i18n.global.t("productInfo.index.categories.bDrive.button"),
    },
    {
        name: i18n.global.t("media.title"),
        link: { name: "media" },
        description: i18n.global.t("media.short_description"),
        image: require("@/assets/img/media/bs_document-download-icon.svg"),
        show: true,
        simpleLink: false,
        target: "_self",
        linkText: i18n.global.t("buttons.discoverButton"),
    },
]);
</script>

<style scoped lang="scss">
@import "@/styles/_variables.scss";
</style>