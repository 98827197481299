<template>
  <Loader :show="loading" v-if="loading"></Loader>
  <div v-else>
    <div v-if="product" class="d-flex flex-column h-100">
      <MDBRow>
        <MDBCol cols="12">
          <Breadcrumbs :parent-routes="['shop']" :route-name="productApi.name" :showGoBack="true" class="pb-3" />
        </MDBCol>
      </MDBRow>
      <MDBRow class="flex-grow-1">
        <MDBCol xl="7" cols="12">
          <MDBCard class="mb-3 mb-md-0 product-image-wrap">
            <MDBCardBody class="d-flex justify-content-center p-relative">
              <div class="video" v-if="product.video">
                <iframe class="responsive-iframe" :src="product.video" allow="fullscreen" title="" data-ready="true"
                  tabindex="-1"></iframe>
              </div>
              <img v-else :src="productImgSrc" :alt="$t('productImg')" class="product-image" />
              <MDBIcon icon="heart" size="2x" :iconStyle="isFavoriteProduct ? 'fas' : 'far'"
                class="p-absolute heart-icon-container cursor--pointer text-danger px-2 py-2" @click="saveFavorite()"
                v-if="isGermany">
              </MDBIcon>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        <MDBCol xl="5" cols="12">
          <MDBCard class="product-main-data product-image-wrap">
            <MDBCardBody class="d-flex flex-column justify-content-between">
              <div class="d-flex flex-column">
                <MDBCardTitle class="font-25"><span>{{ productApi.name }}</span>
                </MDBCardTitle>
                <MDBCardTitle class="font-45 my-3" :class="maxQuantity == 0 ? 'text-warning' : 'text-success'">
                  {{ points }}
                  <span class="font-25 text-dark text-lowercase">{{
                    $t("points")
                    }}</span>
                </MDBCardTitle>
                <template v-if="variants.length > 0">
                  <select class="d-flex" @change="(event) => selectVariant(event)">
                    <option v-for="variant in variants" :key="`variant_${variant.id}`" :value="variant.id"
                      :selected="selectedVariant == variant.id">
                      {{ variant.label }}
                    </option>
                  </select>
                </template>
              </div>
              <MDBBtn color="info" class="w-100" v-if="isAdmin" @click="editProduct">{{ $t("buttons.edit") }}</MDBBtn>
              <template v-if="
                (product.not_available == true &&
                  product.show_empty == true) ||
                product.show_empty == true
              ">
                {{ $t("shop.outOfStock") }}
              </template>
              <template v-else-if="product.not_available == true">
                {{ $t("shop.timeUnavailable") }}
              </template>
              <template v-else>
                <div class="pb-5">
                  <NumberInput :label="$t('quantity')" :step="1" :min="1" :max="maxQuantity" v-model="quantity">
                  </NumberInput>
                </div>
                <MDBBtn :color="maxQuantity == 0 ? 'warning' : 'success'" class="w-100"
                  @click.prevent="addProduct(product)">
                  {{ $t("buttons.toTheBasket") }}
                </MDBBtn>
              </template>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </div>
  </div>
</template>

<script setup>
import Loader from "@/components/General/Loader/index";
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBBtn,
  MDBIcon,
} from "mdb-vue-ui-kit";
import Breadcrumbs from "@/components/General/Breadcrumbs/index";
import { useStore } from "vuex";
import { computed, watch, ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import { ProductService } from "@/services/product.service";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import i18n from "@/plugins/i18n";
import NumberInput from "@/components/Shared/NumberInput.vue";
import config from "@/config";

const store = useStore();
const route = useRoute();
const router = useRouter();

const LANG = localStorage.getItem("lang");
const isGermany = computed(() => LANG === "de");
const quantity = ref(1);
const isWholesaler = ref(false);
const isWholesalerStaff = ref(false);
const isAdmin = ref(false);
const isStaff = ref(false);
const product = ref(null);
const variants = ref(null);
const productImgSrc = ref(null);
const isFavoriteProduct = ref(false);
const selectedVariant = ref("");

const noPointsToast = () =>
  toast(i18n.global.t("shop.notEnoughPoints"), {
    autoClose: 10000,
    type: "warning",
    theme: "colored",
    transition: toast.TRANSITIONS.SLIDE,
    position: toast.POSITION.BOTTOM_RIGHT,
  });

const productApi = computed(() => store.getters.getProduct);

watch(productApi, (newVal) => {
  if (newVal) {
    let hasVariants = newVal.variants && newVal.variants.length > 0;
    product.value = hasVariants
      ? newVal.variants.find((item) => item.is_default == true)
      : newVal;
    variants.value = hasVariants
      ? [...newVal.variants].map((item) => {
        if (item.title) {
          item["label"] = item.title;
          return item;
        } else {
          let attributeOption = JSON.parse(item.attribute_option);
          item["label"] = attributeOption
            .map((item) => {
              return item.label + ": " + item.option;
            })
            .join(", ");
          return item;
        }
      })
      : [];
    const isVariant = newVal.type == "variant";

    productImgSrc.value = isVariant
      ? product.value?.image
      : product.value?.imageUrl;

    if (isVariant) {
      isFavoriteProduct.value =
        newVal &&
        user.value &&
        user.value.favorites &&
        user.value.favorites.length > 0 &&
        user.value.favorites.find(
          (item) =>
            item.product_id == newVal.plainId &&
            item.product_variant_id == product.value.id
        );
    } else {
      isFavoriteProduct.value =
        newVal &&
        user.value &&
        user.value.favorites &&
        user.value.favorites.length > 0 &&
        user.value.favorites.find((item) => item.product_id == newVal.plainId);
    }
  }
});

const user = computed(() => store.getters.getMe);
const cart = computed(() => store.getters.getCart);
const loading = computed(() => store.getters.getPending);
const maxQuantity = computed(() => {
  return Math.floor(
    ((user.value.points || 0) - (cart.value?.total || 0)) /
    parseInt(product.value?.points)
  );
});
const points = computed(() => product.value.points * quantity.value);

watch(user, (newVal) => {
  isWholesaler.value =
    newVal && newVal.roles.find((item) => item.name == "wholesaler");
  isWholesalerStaff.value =
    newVal && newVal.roles.find((item) => item.name == "wholesalerStaff");
  isAdmin.value = newVal && newVal.roles.find((item) => item.name == "admin");
  isStaff.value = newVal && newVal.roles.find((item) => item.name == "staff");

  if (productApi.value && productApi.value.type == "variant") {
    isFavoriteProduct.value =
      newVal &&
      newVal.favorites.find(
        (item) =>
          item.product_id == productApi.value.plainId &&
          item.product_variant_id == product.value.id
      );
  } else {
    isFavoriteProduct.value =
      newVal &&
      newVal.favorites.find(
        (item) => item.product_id == productApi.value.plainId
      );
  }
});

const selectVariant = (event) => {
  product.value = variants.value.filter((item) => {
    return item.id == event.target.value;
  })[0];

  productImgSrc.value = product.value.image;
  isFavoriteProduct.value =
    user.value &&
    user.value.favorites.find(
      (item) =>
        item.product_id == productApi.value.plainId &&
        item.product_variant_id == product.value.id
    );
  selectedVariant.value = product.value.id;
};

const addProduct = async () => {
  if (
    maxQuantity.value == 0 ||
    isWholesalerStaff.value ||
    isStaff.value) {
    noPointsToast();
  } else {
    let data = {
      id: productApi.value.id,
      quantity: quantity.value,
    };

    if (productApi.value.variants.length > 0) {
      data["variant_id"] = product.value.id;
    }

    const toastMessage =
      quantity.value > 1
        ? i18n.global.t("toastMessages.addProducts")
        : i18n.global.t("toastMessages.addProduct");

    await store.dispatch("addItem", data);
    await store.dispatch("fetchUserShopCart");

    toast(toastMessage, {
      type: "success",
      theme: "colored",
      transition: toast.TRANSITIONS.SLIDE,
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  }
};

const editProduct = () => {
  window.open(
    `${config.ADMIN}?token=${user.value.api_token}&productId=${product.value.id}`,
    "_blank"
  );
};

const saveFavorite = async () => {
  const data = {
    user_id: user.value.plainId,
    product_id: productApi.value.plainId,
    product_variant_id:
      productApi.value.type == "variant" ? product.value.id : null,
  };
  await ProductService.saveDeleteFavorite(data);
  await store.dispatch("me");
};

onMounted(async () => {
  await store.dispatch("loadProductById", route.params.id);

  if (product.value.inactive == true) {
    router.push("/shop");
  } else {
    await store.dispatch("clearAttributes");
    await store.dispatch("fetchUserShopCart");

    if (maxQuantity.value == 0 && isWholesalerStaff.value == false) {
      noPointsToast();
    }

    if (variants.value.length > 0) {
      product.value = variants.value.filter((variant) => variant.is_default)[0];
      productImgSrc.value = product.value.image;
      selectedVariant.value = product.value.id;
    }
  }
});
</script>

<style scoped lang="scss">
@import "../../styles/variables";
@import "../../styles/product";
</style>
